@background-color-dark-app: #141414;
@background-color-light-app: lightgrey;

@background-color-dark-code-result: #141414;
@background-color-light-code-result: white;

@background-color-dark-final-result: #141414;
@background-color-light-final-result: white;

@background-color-dark-pipe-table: #141414;
@background-color-light-pipe-table: white;

@background-color-dark-side-bar: #141414;
@background-color-light-side-bar: white;

@background-color-dark-menu-item: #141414;
@background-color-light-menu-item: white;

@background-color-dark-toolbar: #1d1f23;
@background-color-light-toolbar: white;

@background-color-dark-divider: rgba(255, 255, 255, 0.12);
@background-color-light-divider: rgba(0, 0, 0, 0.1);

@background-color-favorite-star: #ffc800;

@duration-animation: 200ms;

.for-phone-only(@rules) {
  @media (max-width: 599px) {
    @rules();
  }
}

.for-tablet-portrait-up(@rules) {
  @media (min-width: 600px) {
    @rules();
  }
}

.for-tablet-landscape-up(@rules) {
  @media (min-width: 900px) {
    @rules();
  }
}

.for-desktop-up(@rules) {
  @media (min-width: 1200px) {
    @rules();
  }
}

.for-big-desktop-up(@rules) {
  @media (min-width: 1800px) {
    @rules();
  }
}

// https://www.w3schools.com/howto/howto_css_custom_scrollbar.asp
.has-custom-scrollbar(@track-color, @thumb-color) {
  @dimension: 6px;

  ::-webkit-scrollbar {
    height: @dimension;
    width: @dimension;
  }

  ::-webkit-scrollbar-track {
    background: @track-color;
  }

  ::-webkit-scrollbar-thumb {
    background: @thumb-color;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: lighten(@thumb-color, 5);
  }
}

.has-firefox-custom-scrollbar(@track-color, @thumb-color) {
  // For Firefox: https://developer.mozilla.org/en-US/docs/Web/CSS/scrollbar-width
  scrollbar-color: @thumb-color @track-color;
  scrollbar-width: thin;
}
