@import "@/_global.less";

.app-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  &_theme {
    &-dark {
      @scrollbar-track-color: @background-color-dark-app;
      @scrollbar-thumb-color: lighten(@background-color-dark-app, 10);

      .has-custom-scrollbar(
        @track-color: @scrollbar-track-color,
        @thumb-color: @scrollbar-thumb-color,
      );

      * {
        .has-firefox-custom-scrollbar(
          @track-color: @scrollbar-track-color,
          @thumb-color: @scrollbar-thumb-color,
        );
      }

      background-color: @background-color-dark-app;
    }

    &-light {
      @scrollbar-track-color: white;
      @scrollbar-thumb-color: darken(white, 30);

      .has-custom-scrollbar(
        @track-color: @scrollbar-track-color,
        @thumb-color: @scrollbar-thumb-color,
      );

      * {
        .has-firefox-custom-scrollbar(
          @track-color: @scrollbar-track-color,
          @thumb-color: @scrollbar-thumb-color,
        );
      }

      background-color: @background-color-light-app;
    }
  }

  &_dim {
    opacity: 0.3;
  }

  // Block mobile view
  .for-phone-only({
    overflow-x: hidden;
    overflow-y: hidden;
  });

  @height-toolbar: 4.8rem;
  @height-divider: 1px;

  &__toolbar {
    border-bottom-width: @height-divider;
    border-bottom-style: solid;
    height: @height-toolbar;

    &_theme {
      &-dark {
        background-color: @background-color-dark-toolbar;
        border-bottom-color: @background-color-dark-divider;
      }

      &-light {
        background-color: @background-color-light-toolbar;
        border-bottom-color: @background-color-light-divider;
      }
    }
  }

  &__app-content {
    height: calc(100% - @height-toolbar - @height-divider);
    width: 100%;
  }
}
