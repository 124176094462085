.my-pipelines-toolbar-element-container {
  align-items: center;
  display: flex;
  justify-content: flex-end;

  &__child {
    margin-left: 0.8rem;

    &:first-child {
      margin-left: 0;
    }
  }
}
