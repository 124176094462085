.toolbar-container {
  align-items: center;
  display: flex;
  padding: 1.2rem;

  &__dynamic-elements {
    flex: 1 0 0;
    margin-left: 1.2rem;
  }
}
