.drawer-container {
  &__content {
    height: 100%;
    position: relative;
  }

  &__menu {
    &_theme {
      &-dark.ant-menu {
        // In dark mode, the menu has a custom background color that does not
        // match with the drawer background color, so we need to unset it here.
        background-color: initial;
      }
    }
  }

  // Increase CSS specificity to override antd styles
  &__theme-switch.ant-switch {
    bottom: 0;
    position: absolute;
    right: 0;
  }
}
