@import "@/_global.less";

.loading-logo {
  @dimension: 4rem;
  animation: rotate 1s ease-in-out infinite;
  height: @dimension;
  transform: rotateY(360deg);
  width: @dimension;

  @keyframes rotate {
    from {
      transform: rotateY(0);
    }

    to {
      transform: rotateY(360deg);
    }
  }
}
