.tutorial-view-container {
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: -1;

  &_finished-delay {
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 999;
  }

  &__tutorial {
    pointer-events: none;
    position: absolute;
  }

  /** Increase specificity to override antd styles */
  &__skip.ant-btn {
    bottom: 1.2rem;
    position: absolute;
    right: 1.2rem;
  }

  &__active-tutorial {
    z-index: 1000;
  }
}
