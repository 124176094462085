@import "@/_global.less";

.loadable-loading-container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 1.2rem;
  width: 100%;
}
