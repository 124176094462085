* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // Disable overflow to prevent a weird issue with tooltip disappearing and
  // causing the body to temporarily overflow with a scrollbar.
  overflow-x: hidden;
  overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

// Add a small margin so that the icon is not too close to the text
button[type="button"] * + span[role="img"] {
  margin-left: 0.2rem;
}

div[role="tablist"].ant-tabs-nav {
  margin-bottom: 0;
}

.ant-modal-wrap {
  align-items: center;
  display: flex;
  justify-content: center;
}

// Increase CSS specificity to override antd styles
div[role="dialog"].ant-modal {
  top: 0;
}

.ant-table-body {
  // antd use overflow-y: scroll, which shows the scrollbar at all times. Since
  // the scrollbar looks bad on Windows, we will try to hide it as much as
  // possible.
  overflow-y: auto !important;
}
