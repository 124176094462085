@import "@/_global.less";

.pipeline-toolbar-element-container {
  align-items: center;
  display: flex;
  justify-content: flex-end;

  &__child {
    margin-left: 0.8rem;

    &:first-child {
      margin-left: 0;
    }
  }

  &__pipeline-name {
    margin-right: auto;
  }

  // Increase specificity to override antd styles
  &__favorited-icon.anticon {
    color: @background-color-favorite-star;
  }
}
